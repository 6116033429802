import React from 'react'

import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'

import { Carousel } from 'mx/components/new/'
import {
  ContentModule,
  getParagraph,
  renderLineBreak,
} from 'mx/components/new/utils'

interface ContentColumnCarouselProps {
  classname?: TwStyle
  data: ContentModule
  isCaseStudies?: boolean
}

export const ContentColumnCarousel = ({
  classname,
  data,
  isCaseStudies,
}: ContentColumnCarouselProps) => {
  const { contentSlots } = data
  if (!contentSlots) return null
  const { markdown, slotTitle, photos } = contentSlots[0]
  const { markdown: kitchenSpecs } = contentSlots[1]

  return (
    <div
      tw="w-full flex flex-col mx-auto space-y-14 md:space-y-10 text-gray-dark font-light px-6 md:px-8 md:flex-row md:justify-between md:max-w-6xl"
      css={[classname]}
    >
      <div tw="w-full space-y-6 md:space-y-12">
        {isCaseStudies && (
          <div tw="space-y-12 md:space-y-16 md:hidden border-t border-gray-dark pt-2">
            {!!slotTitle && (
              <h3 tw="text-3xl max-w-xs">{renderLineBreak(slotTitle)}</h3>
            )}
            {getParagraph(markdown, tw`text-base md:text-lg text-gray-500`)}
          </div>
        )}
        <div tw="w-full">
          <Carousel images={photos} />
        </div>
        <div tw="flex flex-col max-md:pt-6 md:flex-row md:gap-14 lg:gap-20">
          <div
            tw="space-y-8 max-w-sm md:w-1/2 lg:max-w-xl"
            css={[isCaseStudies && tw`max-md:hidden`]}
          >
            {!!slotTitle && (
              <h3 tw="text-4xl max-w-md" css={[isCaseStudies && tw`max-w-xs`]}>
                {renderLineBreak(slotTitle)}
              </h3>
            )}
            {getParagraph(markdown, tw`text-brown-medium text-xl`)}
          </div>
          <div tw="space-y-4">
            {getParagraph(kitchenSpecs, tw`text-base md:text-lg max-w-sm`)}
          </div>
        </div>
      </div>
    </div>
  )
}
